import errorIllustration from '@assets/error/errorInfo.svg'
import Image from 'next/image'
import NotFoundGoBackBtn from '@components/atoms/NotFoundGoBackBtn'

const NotFound = () => {
  return (
    <div className=" flex py-2">
      <Image src={errorIllustration} alt="404" className=" flex-1" />
      <div className=" flex-1 flex flex-col gap-0 font-nunito">
        <div className=" text-[255px] font-bold p-0">
          40<span className=" text-primary-500">4</span>
        </div>
        <div className=" headline_large">Oops! Looks like the event map got tangled.</div>
        <div className=" label_medium text-neutral-800 pb-16">
          The page you're looking for isn't available. Try to search again or use the go to homepage
          button.
        </div>
        <NotFoundGoBackBtn />
      </div>
    </div>
  )
}

export default NotFound
