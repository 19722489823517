'use client'
import NotFound from './not-found'
const error = () => {
  return (
    <div>
      <NotFound />
    </div>
  )
}

export default error
